import React from 'react';
import { useMemo } from 'react';
import { IComponent, ITextBanner } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import ComponentWrapper from './component-wrapper';

const TextBanner = ({ blok: { body, anchor, backgroundColor }}: IComponent<ITextBanner>) => {
	const html = useMemo(() => richTextResolver(body), []);
	
	return (
		<ComponentWrapper anchor={anchor} className='card-wrapper text-banner' backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}>
			<div className='box-wide'>
				<div className='text-banner__inner content-editor' dangerouslySetInnerHTML={htmlRaw(html)}></div>
			</div>
		</ComponentWrapper>
	);
};

export default TextBanner;
