export interface IComponent<T> {
	blok: T;
}

interface IComponentBase {
	anchor: string;
	component: string;
	_uid: string;
	backgroundColor: IColorPicker[];
}

export interface IPictureQuery {
	height: number;
	width: number;
	media: string;
	alias: string;
}

export interface IMedia {
	id: number;
	copyright: string;
	fieldtype: string;
	filename: string;
	focus: string;
	name: string;
	title: string;
	alt: string;
}

export interface ILink {
	caption: string;
	component: string;
	id?: string;
	classes?: string;
	link?: {
		cached_url: string,
		fieldtype: string,
		id: string,
		linktype:string,
		url: string,
		anchor: string
	}
}

export interface ITextBanner extends IComponentBase {
	body: IRichTextElement;
}

export enum IconType { TriangleTriangle = 'triangle-triangle', CircleSquare = 'circle-square', Circle = 'circle' }

export interface ITextColumnWitchIcon {
	iconType: IconType;
	body: IRichTextElement;
	link: ILink;
}

export interface ITextBannerWithColumns extends ITextBanner {
	body: IRichTextElement;
	video: IMedia,
	image: IMedia,
	text: IRichTextElement,
	columns: ITextColumnWitchIcon[];
}

export interface ITwoMediaWithText {
	title: string,
	firstVideo:IMedia,
	secondVideo: IMedia,
	firstImage: IMedia,
	secondImage: IMedia,
	firstMediaSubtitle: string,
	firstMediaDescription: string,
	secondMediaSubtitle: string,
	secondMediaDescription: string,
	download_label: string,
	downloadable_file: string,
	anchor: string,
	video_1: string,
	video_2: string,
	text_color: {
		value: string
	},
	background_color: {
		value: string
	}
}

export interface IHero extends IComponentBase {
	video: IMedia;
	image: IMedia;
	text: IRichTextElement;
}

interface IColorPicker {
	color: string;
}

export interface IRichTextElement {
	type: string;
	content: IRichTextElement[];
}

export interface ITextWithImageBanner extends IComponentBase {
	image: IMedia;
	body: IRichTextElement;
	positionImageOnTheRight: boolean;
	fullHeightImage: boolean;
}

export interface IIconWithText {
	icon: IMedia;
	text: string;
}

export interface IIconsCarousel extends IComponentBase {
	headline: string;
	description: string;
	iconsBlocks: IIconWithText[];
}

export interface IEmployee {
	title: string;
	fullName: string;
	email_address: string;
	description: string;
	image: IMedia;
}

export interface IEmployeesComponent extends IComponentBase {
	headline: string;
	caption: string;
	employees: IEmployee[];
}


export interface IFooter {
	footerAnchor: string;
	footerCaption: string;
	footerBackgroundColor: IColorPicker[];
	footerBody: IRichTextElement;
	footerColumns: IRichTextElement[];
}

export interface IPane {
	title: string;
	body: IRichTextElement;
}

export interface IPaneSlider extends IComponentBase {
	panes: IPane[];
}

interface IFlippingTextItem {
	headline: string;
	bodyText: string;
}

export interface IFlippingTextComponent extends IComponentBase {
	bodyText: IRichTextElement;
	flippingTextList: IFlippingTextItem[];
}

export interface ITextBannerWithIllustration extends IComponentBase {
	illustrationType: string;
	bodyText: IRichTextElement;
	positionIllustrationOnTheRight: boolean;
}
