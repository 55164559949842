import React, { useState } from 'react';
import clsx from 'clsx';
import { IComponent, IEmployeesComponent } from '../types/global.types';
import ComponentWrapper from './component-wrapper';
import Picture from './picture';

const EmployeesComponent = ({ blok: { anchor, caption, headline, employees, backgroundColor }}: IComponent<IEmployeesComponent>) => {
	const queries = [
		{ alias: 'desktop', height: 486, width: 415, media: '' },
	];
	return (
		<ComponentWrapper anchor={anchor} className='employees-component' backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}>
			<div className='employees-component__inner-wrapper box-wide'>
				<div className='employees-component__sidebar'>
					<div className='employees-component__sidebar__content'>
						{caption &&
							<p className='employees-component__sidebar__caption'>{caption}</p>
						}
						{headline &&
							<h2 className='employees-component__sidebar__headline'>{headline}</h2>
						}
					</div>
				</div>
				<div className='employees-component__list-container'>
					{employees.map((employee, index) => 
						<EmployeeTile
							key={index}
							employee={employee}
							queries={queries}
						/>,
					)}
				</div>
			</div>
		</ComponentWrapper>
	);
};

const EmployeeTile = ({ employee, queries }) => {
	const {
		fullName,
		image,
		description,
		title,
		email_address,
	} = employee;
	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

	return (
		<div className='employees-component__employee'>
			<div>
				<div className={clsx('employees-component__employee__figure', {
					'employees-component__employee__figure--show-background': isDescriptionExpanded,
				})}>
					<Picture image={image} queries={queries} />
					<div className={clsx('employees-component__employee__description-wrapper', {
						'employees-component__employee__description-wrapper--visible': isDescriptionExpanded,
					})}>
						<p className='employees-component__employee__description'>
							{description}
						</p>
					</div>
					<button
						onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
						className={clsx('employees-component__employee__toggle', {
							'employees-component__employee__toggle--active': isDescriptionExpanded,
						})}
					>
						<svg xmlns='http://www.w3.org/2000/svg' width='11.592' height='11.76' viewBox='0 0 11.592 11.76'>
							<path id='Path_63' data-name='Path 63' d='M21.3,13.544h-4.56V8.864H14.264v4.68H9.7v2.4h4.56v4.68h2.472v-4.68H21.3Z' transform='translate(-9.704 -8.864)' fill='#5871a0'/>
						</svg>
					</button>
				</div>
				<p className='employees-component__employee__title'>{title}</p>
				<h3 className='employees-component__employee__name'>{fullName}</h3>
				{email_address?.email &&
					<a href={`mailto:${email_address.email}`} className='employees-component__employee__email'>
						{email_address.email}
					</a>
				}
			</div>
		</div>
	);
};

export default EmployeesComponent;
