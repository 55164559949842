import React from 'react';
import Layout from '../components/layout';
import Meta from '../components/meta';
import DynamicComponent from '../components/dynamic-component';
import { useStoryblok } from '../lib/storyblok';
import Hero from '../components/hero';
import { IFooter } from '../types/global.types';

const TemplatePage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);
	const {
		header,
		hero,
		body,
		footerAnchor,
		footerBackgroundColor,
		footerBody,
		footerCaption,
		footerColumns,
		title,
		meta_title,
		meta_image,
		meta_description,
	} = story.content;

	const components = body.map(blok => (
		<DynamicComponent key={blok._uid} blok={blok} />
	));
	const footer: IFooter = { footerAnchor, footerBackgroundColor, footerBody, footerCaption, footerColumns };

	return (
		<Layout header={header} footer={footer}>
			<Meta
				title={title}
				ogTitle={meta_title}
				ogDescription={meta_description}
				ogImage={meta_image ? meta_image.filename : ''}
			/>
			<Hero {...hero[0]} />
			<div className='main__components-wrapper'>
				{components}
			</div>
		</Layout>
	);
};

export default TemplatePage;
