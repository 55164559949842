import React, { useMemo } from 'react';
import { IComponent, ITextWithImageBanner } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import ComponentWrapper from './component-wrapper';
import Picture from './picture';


const TextWithImageBanner = ({ blok: { body, image, positionImageOnTheRight: positionImageInTheRight, fullHeightImage, backgroundColor, anchor }}: IComponent<ITextWithImageBanner>) => {
	const html = useMemo(() => richTextResolver(body), []);
	const queries = [
		{ alias: 'desktop', height: 356, width: 634, media: '(min-width: 1024px)' },
		{ alias: 'tablet', height: 264, width: 472, media: '(min-width: 769px)' },
		{ alias: 'mobileLarge', height: 396, width: 707, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 247, width: 440, media: '(max-width: 480px)' },
	];
	const fullWidthQueries = [
		{ alias: 'desktop', height: 1221, width: 1081, media: '(min-width: 1024px)' },
		{ alias: 'tablet', height: 768, width: 512, media: '(min-width: 768px)' },
		{ alias: 'mobileLarge', height: 512, width: 768, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 896, width: 425, media: '(max-width: 480px)' },
	];
	
	return (
		<ComponentWrapper 
			anchor={anchor} 
			className={`
				card-wrapper
				text-with-image-banner 
				text-with-image-banner--${positionImageInTheRight ? 'right' : 'left'}
				${fullHeightImage ? 'text-with-image-banner--full-height' : ''}
			`} 
			backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}
		>
			<div className='box-wide  text-with-image-banner__inner-wrapper'>
				<div className={`text-with-image-banner__picture text-with-image-banner__picture--${fullHeightImage ? 'full-height' : ''}`} >
					<Picture image={image} queries={fullHeightImage ? fullWidthQueries : queries} />
				</div>
				<div className='text-with-image-banner__content'>
					<div className='text-with-image-banner__content__body content-editor' dangerouslySetInnerHTML={htmlRaw(html)}></div>
				</div>
			</div>
		</ComponentWrapper>
	);
};

export default TextWithImageBanner;
