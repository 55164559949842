import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useView } from '../hooks/use-view';
import { useWindowSize } from '../hooks/use-window-size';
import { IComponent, IPaneSlider } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import ComponentWrapper from './component-wrapper';

const DEFAULT_PANE_ITEM_SIZE = 50;

const PaneMarkup = ({ panes, panesRef, panesInnerRef, currentPaneChangeHandler, panesHtml, id}) => (
	panes.map((p, index) =>
		<div ref={(el) => panesRef.current[index] = {el: el, open: false}} className={`pane-slider__item ${panesRef.current[index]?.open ? 'pane-slider__item--open' : ''}`}  id={`${id}-${index}`} key={`${id}-${index}`}>
			<div ref={(el) => panesInnerRef.current[index] = el} className='pane-slider__item__inner'>
				<button className='pane-slider__item__button' onClick={() => currentPaneChangeHandler(index)}><span  className='pane-slider__item__button__caption'>{p.title}</span></button>
				<div className=' box-wide pane-slider__item__body content-editor' dangerouslySetInnerHTML={htmlRaw(panesHtml[index])}></div>
			</div>
		</div>,
	)
);

const DesktopPanes = ({panes, panesHtml}) => {
	const panesRef = useRef<{el: HTMLDivElement, open: boolean}[]>([]);
	const panesInnerRef = useRef<HTMLDivElement[]>([]);
	const boxWidePlaceholderRef = useRef<HTMLDivElement>(null); //we use this as a guide for aligning content to the left following the positioning of other components
	const [ currentPaneIndex, setCurrentPaneIndex ] = useState(0);
	const { width } = useWindowSize();

	const currentPaneChangeHandler = (nextPane) => {
		if(currentPaneIndex === nextPane) { return; }
		
		gsap.to(panesRef.current[currentPaneIndex].el, { width: `${DEFAULT_PANE_ITEM_SIZE}px` });
		panesRef.current[currentPaneIndex].open = null;

		setCurrentPaneIndex(nextPane);
	};

	const expandItem = () => {
		if(width >= 1280) {
			const buttonsOnLeftSideWidth = DEFAULT_PANE_ITEM_SIZE * (currentPaneIndex + 1);
			const leftPositionOfBoxWide = boxWidePlaceholderRef.current.getBoundingClientRect().left;
			const offsetNeededToAlignWithTheRestOfComponents = leftPositionOfBoxWide - buttonsOnLeftSideWidth;
			gsap.set(panesInnerRef.current[currentPaneIndex], { paddingLeft: offsetNeededToAlignWithTheRestOfComponents > 100 ? offsetNeededToAlignWithTheRestOfComponents : 100 });
		} else {
			gsap.set(panesInnerRef.current[currentPaneIndex], { paddingLeft: 0 });
		}

		const windowWidth = document.documentElement.clientWidth;
		const collapsedElementsWidth = DEFAULT_PANE_ITEM_SIZE * (panes.length - 1);
		gsap.to(panesRef.current[currentPaneIndex].el, { width: `${windowWidth - collapsedElementsWidth}px` });

		panesRef.current[currentPaneIndex].open = true;
	};

	useEffect(() => {

		if(!panesRef.current[currentPaneIndex].open){
			expandItem();
		}
	}, [currentPaneIndex, width]);

	return (
		<>
			<div ref={boxWidePlaceholderRef} className='pane-slider__box-wide-placeholder box-wide'></div>
			<PaneMarkup panes={panes} panesHtml={panesHtml} currentPaneChangeHandler={currentPaneChangeHandler} panesRef={panesRef} panesInnerRef={panesInnerRef} id='desktop' />
		</>
	);
};

const NonDesktopPanes = ({panes, panesHtml}) => {
	const panesRef = useRef<{el: HTMLDivElement, open: boolean}[]>([]);
	const panesInnerRef = useRef<HTMLDivElement[]>([]);

	const expandItem = (paneIndex) => {
		gsap.to(panesRef.current[paneIndex].el, {
			height: `${panesInnerRef.current[paneIndex].offsetHeight}px`,
			onComplete: () => ScrollTrigger.refresh(true),
		});
		panesRef.current[paneIndex].open = true;
	};

	const currentPaneChangeHandler = (paneIndex) => {
		const pane = panesRef.current[paneIndex];
		if(pane.open) {
			gsap.to(pane.el, {
				height: `${DEFAULT_PANE_ITEM_SIZE}px`,
				onComplete: () => ScrollTrigger.refresh(true),
			});
			pane.open = false;
		} else {
			expandItem(paneIndex);
		}
	};
	
	return <PaneMarkup panes={panes} panesHtml={panesHtml} currentPaneChangeHandler={currentPaneChangeHandler} panesRef={panesRef} panesInnerRef={panesInnerRef} id='non-desktop' />;
};

const PaneSlider = ({ blok: { anchor, panes }}: IComponent<IPaneSlider>) => {
	const { isDesktop } = useView();
	const panesHtml = useMemo(() => panes.map(p => richTextResolver(p.body)), []);

	return (
		<ComponentWrapper anchor={anchor} className='pane-slider'>
			{isDesktop 
				?
				<DesktopPanes panes={panes} panesHtml={panesHtml} />
				:
				<NonDesktopPanes panes={panes} panesHtml={panesHtml} />
			}
		</ComponentWrapper>
	);
};

export default PaneSlider;
