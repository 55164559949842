import React from 'react';
import Helmet from 'react-helmet';

type MetaProps = {
	title?:string;
	language?:string;
	description?:string;
	keywords?:string[];
	ogTitle?:string;
	ogDescription?:string;
	ogImage?:string;
	ogUrl?:string;
	ogType?:string;
}

const Meta = ({
	title,
	language,
	ogDescription,
	ogTitle,
	ogImage,
}: MetaProps) => {

	const { globalContent: { metaData: data } } = { globalContent: { metaData: null } };

	const lang = language || data?.lang || 'en';
	const metaTitle = title || data?.documentTitle || 'Jera Capital';
 
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			bodyAttributes={{
				class: 'body',
			}}
			title={metaTitle}
			meta={[
				{
					name: 'description',
					content: ogDescription,
				},
				{
					property: 'og:title',
					content: ogTitle,
				},
				{
					property: 'og:description',
					content: ogDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: ogImage,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: '',
				},
				{
					name: 'twitter:title',
					content: ogTitle,
				},
				{
					name: 'twitter:description',
					content: ogDescription,
				},
			]}
		>
			<link rel='stylesheet' href='https://use.typekit.net/rwj8gii.css'/>
		</Helmet>
	);
};

export default Meta;
