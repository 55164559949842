import React, { useRef } from 'react';
import { IMedia, IPictureQuery } from '../types/global.types';
import { useEffect } from 'react';
import { useState } from 'react';
import { useIntersectionObserver } from '../hooks/use-intersection-observer';

type PictureProps = {
	queries?: IPictureQuery[];
	image: IMedia;
}

const Picture = ({ image, queries = null }: PictureProps) => {
	if (!image) { return <></>; }
	const { filename, alt } = image;
	const ref = useRef(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);
	const [ imageIsLoaded, setImageIsLoaded ] = useState(false);
	const [ initialLoad, setInitialLoad ] = useState(false);
	const [ imageToSetListenersTo, setImageToSetListenersTo ] = useState(null);

	const loadHandler = () => {
		setImageIsLoaded(true);
	};

	const errorHandler = () => {
		console.error(`Image ${imageToSetListenersTo.src} could not be loaded.`);
		ref.current.classList.add('missing-image');
	};

	const lazyLoadImage = () => {
		const img = ref.current.querySelector('img');
		if(img) {
			ref.current.querySelectorAll('source').forEach((source:HTMLSourceElement) => {
				source.srcset = source.dataset.srcset;
			});

			if(img.dataset.src) {
				img.src = img.dataset.src;
				if (img.complete) {
					setImageIsLoaded(true);
				} else {
					setImageToSetListenersTo(img);
				}
			} else {
				ref.current.classList.add('missing-image');
			}
		}
	};

	useEffect(() => {
		if(imageToSetListenersTo) {
			imageToSetListenersTo.addEventListener('load', loadHandler);
			imageToSetListenersTo.addEventListener('error', errorHandler);

			return () => {
				imageToSetListenersTo.removeEventListener('load', loadHandler);
				imageToSetListenersTo.removeEventListener('error', errorHandler);
			};
		}
	}, [imageToSetListenersTo]);
	
	useEffect(() => {
		if(initialLoad && imageIsLoaded) {
			const img = ref.current.querySelector('img');
			img.src = image.filename;
		}
	}, [image.filename]);

	useEffect(() => {
		if(isIntersecting && !initialLoad) {
			lazyLoadImage();
			setInitialLoad(true);
		}
	}, [isIntersecting]);
	
	return (
		<picture ref={ref} className={`picture ${imageIsLoaded ? 'picture--loaded' : ''}`}>
			{queries && queries.map(q => 
				<source key={q.alias} data-srcset={`${filename}/m/${q.width}x${q.height}`} media={q.media} />,
			)}
			<img data-src={filename} alt={alt} className='picture__image' />
		</picture>
	);
};

export default Picture;
