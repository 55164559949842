import React from 'react';
import { useMemo } from 'react';
import CircleIcon from '../svg/circle-icon';
import CircleSquareIcon from '../svg/circle-square-icon';
import TriangleTriangleIcon from '../svg/triangle-triangle-icon';
import { IComponent, IconType, ITextBannerWithColumns } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import ComponentWrapper from './component-wrapper';
import CustomLink from './custom-link';

const TextBannerWithColumns = ({ blok: { body, columns, anchor, backgroundColor, video, image, text }}: IComponent<ITextBannerWithColumns>) => {
	const html = useMemo(() => richTextResolver(body), []);
	const videoHtml = useMemo(() => richTextResolver(text), []);
	const columnsHtml = useMemo(() => columns.map(c => richTextResolver(c.body)), []);

	return (
		<ComponentWrapper anchor={anchor} className='card-wrapper text-banner-with-columns' backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}>
			<div className='box-wide'>
				<div className='text-banner-with-columns__body content-editor' dangerouslySetInnerHTML={htmlRaw(html)}></div>
				{(video && video.filename) ?	
					<div className='text-banner-with-columns__video-section'>
						<div className='text-banner-with-columns__video-container'>
							<video className='text-banner-with-columns__video' playsInline controls poster={image?.filename}>
								<source src={video.filename} type='video/mp4'/>
							</video>
						</div>
						<div className='text-banner-with-columns__text-container content-editor'>
							<div className='text-banner-with-columns__text' dangerouslySetInnerHTML={htmlRaw(videoHtml)}></div>
						</div>
					</div> : null}
				<div className='text-banner-with-columns__columns-wrapper'>
					{columns.map((c, index) =>
						<CustomLink className='text-banner-with-columns__column' link={c.link[0]} key={index}>
							<div>
								<div className='text-banner-with-columns__column__icon-wrapper'>
									{c.iconType === IconType.TriangleTriangle && <TriangleTriangleIcon  className='text-banner-with-columns__column__icon'/>}
									{c.iconType === IconType.Circle && <CircleIcon  className='text-banner-with-columns__column__icon'/>}
									{c.iconType === IconType.CircleSquare && <CircleSquareIcon  className='text-banner-with-columns__column__icon'/>}
								</div>
								<div className='text-banner-with-columns__column__body content-editor' dangerouslySetInnerHTML={htmlRaw(columnsHtml[index])}></div>
							</div>
							<div className='text-banner-with-columns__column__link'>{c.link[0].caption}</div>
						</CustomLink>,
					)}
				</div>
			</div>
		</ComponentWrapper>
	);
};

export default TextBannerWithColumns;
