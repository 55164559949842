import React from 'react';
import { useMemo } from 'react';
import { IFooter } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';

const Footer = ({footerAnchor, footerCaption, footerBody, footerColumns, footerBackgroundColor}: IFooter) => {
	const bodyHtml = useMemo(() => richTextResolver(footerBody), []);
	const footerColumnsHtml = useMemo(() => footerColumns?.map(fc => richTextResolver(fc.content)), []);

	return (
		<footer id={footerAnchor} className={`card-wrapper footer ${footerBackgroundColor[0]?.color ?? 'white-background'}`}>
			<div className='box-wide'>
				<span className='footer__caption'>{footerCaption}</span>
				<div className='footer__body' dangerouslySetInnerHTML={htmlRaw(bodyHtml)}></div>
				<div className='footer__columns'>
					{footerColumnsHtml?.map((fc, index) => <div className='footer__columns__item' key={index} dangerouslySetInnerHTML={htmlRaw(fc)}></div>)}
				</div>
			</div>
		</footer>
	);
};

export default Footer;