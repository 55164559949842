import { graphql, useStaticQuery } from 'gatsby';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const CANVAS_WIDTH = 410;
const CANVAS_HEIGHT = 545;

const AnimatedWheel = (_props, ref) => {
	const { allFile: { imageFiles }} = useStaticQuery(graphql`
		query MyQuery {
			allFile(filter: {relativeDirectory: {eq: "wheel-loop"}}) {
				imageFiles: edges {
					node {
						publicURL
					}
				}
			}
		}
	`);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [ images, setImages ] = useState([]);
	let currentFrame = 0;

	useImperativeHandle(ref, () => ({
		play() {
			animate();
		},	
	}));

	const getFrame = index => {
		const fileIndex = (index + 1).toString().padStart(2,0);
		const fileEnding = `${fileIndex}.png`;
		const image = imageFiles.find((file) => file.node.publicURL.endsWith(fileEnding));
		return image.node.publicURL;
	};

	const loadImages = async () => {
		return new Promise((resolve) => {
			let loadedImages = 0;
			for (let index = 0; index < imageFiles.length; index++) {
				const image = new Image();
				image.src = getFrame(index);
				setImages(prev => [ ...prev, image]);
				image.onload = () => {
					loadedImages++;
					if(loadedImages >= imageFiles.length) {
						resolve(true);
					}
				};
			}
		});
	};

	const animate = () => {
		if(!images[currentFrame]) { return; }

		const context = canvasRef.current.getContext('2d');
		context.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
		context.drawImage(images[currentFrame], 0, 0);

		if (currentFrame === imageFiles.length - 1) {
			currentFrame = 0;
		} else {
			currentFrame++;
			requestAnimationFrame(animate);
		}
	};

	useEffect(() => {
		if(images.length === imageFiles.length) {
			animate();
		}
	}, [images]);

	useEffect(() => {
		loadImages();
	}, []);

	return (
		<div className='animated-wheel' >
			<canvas ref={canvasRef} height={CANVAS_HEIGHT} width={CANVAS_WIDTH} className='animated-wheel__canvas' />
		</div>
	);
};

export default forwardRef(AnimatedWheel);