import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useState } from 'react';
import { useWindowSize } from '../hooks/use-window-size';
import { useView } from '../hooks/use-view';

const DEFAULT_RADIUS = 213;

const BubbleUp = ({ className = '' }) => {
	const [ bubbleRadius, setBubbleRadius ] = useState(DEFAULT_RADIUS);
	const containerRef = useRef<HTMLDivElement>(null);
	const clipPathRef = useRef(null);
	const { width } = useWindowSize();
	const { isDesktop } = useView();

	useEffect(() => {
		gsap.fromTo(clipPathRef.current, { attr:{ r: 0 }, transformOrigin: 'center center' }, {
			attr:{ r: bubbleRadius },
			transformOrigin: 'center center',
			scrollTrigger: {
				trigger: containerRef.current,
				start: isDesktop ? 'top 70%' : 'top 100%',
				end: isDesktop ? 'top 40%' : 'top 50%',
				scrub: 1,
				invalidateOnRefresh: true,
			},
		});

		return () => {
			gsap.killTweensOf(clipPathRef.current);
		};
	}, [bubbleRadius]);

	useEffect(() => {
		const halfContainerWidth = containerRef.current.offsetWidth / 2;
		const radius = halfContainerWidth < DEFAULT_RADIUS ? halfContainerWidth : DEFAULT_RADIUS;
		setBubbleRadius(radius);
	}, [width]);

	return (
		<div ref={containerRef} className={`bubble-up ${className}`}>
			<svg className='bubble-up__svg'>
				<defs>
					<clipPath id='bubbleUpClip'>
						<circle className='bubble-up__svg__circle' ref={clipPathRef} cx={bubbleRadius} cy={bubbleRadius} r={bubbleRadius} fill='black'/>
					</clipPath>
				</defs>
			</svg>
			<div className='bubble-up__ellipse'></div>
		</div>
	);
};

export default BubbleUp;