import React, { useMemo } from 'react';
import BubbleUp from '../svg/bubble-up';
import RisingSun from '../svg/rising-sun';
import { IComponent, ITextBannerWithIllustration } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import ComponentWrapper from './component-wrapper';

const TextBannerWithIllustration = ({ blok: { bodyText, illustrationType, positionIllustrationOnTheRight, backgroundColor, anchor }}: IComponent<ITextBannerWithIllustration>) => {
	const html = useMemo(() => richTextResolver(bodyText), []);

	return (
		<ComponentWrapper 
			anchor={anchor} 
			className={`
				card-wrapper
				text-banner-with-illustration
				text-banner-with-illustration--${positionIllustrationOnTheRight ? 'right' : 'left'}
			`} 
			backgroundColor={backgroundColor[0]?.color}
		>
			<div className='box-wide  text-banner-with-illustration__inner-wrapper'>
				<div className='text-banner-with-illustration__illustration' >
					{illustrationType === 'rising-sun' && <RisingSun />}
					{illustrationType === 'bubble-up' && <BubbleUp />}
				</div>
				<div className='text-banner-with-illustration__content'>
					<div className='text-banner-with-illustration__content__body content-editor' dangerouslySetInnerHTML={htmlRaw(html)}></div>
				</div>
			</div>
		</ComponentWrapper>
	);
};

export default TextBannerWithIllustration;
