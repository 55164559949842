import React from 'react';
import SbEditable from 'storyblok-react';
import EmployeesComponent from './employees-component';
import FlippingTextComponent from './flipping-text-component';
import IconsCarousel from './icons-carousel';
import PaneSlider from './pane-slider';
import TextBanner from './text-banner';
import TextBannerWithColumns from './text-banner-with-columns';
import TextBannerWithIllustration from './text-banner-with-illustration';
import TextWithImageBanner from './text-with-image-banner';
import TwoMediaWithText from './two-media-with-text';

const Components = {
	'textWithImageBanner': TextWithImageBanner,
	'textBanner': TextBanner,
	'iconsCarousel': IconsCarousel,
	'employeesComponent': EmployeesComponent,
	'textBannerWithColumns': TextBannerWithColumns,
	'paneSlider': PaneSlider,
	'flippingTextComponent': FlippingTextComponent,
	'textBannerWithIllustration': TextBannerWithIllustration,
	'twoMediaWithText': TwoMediaWithText,
};

const DynamicComponent = ({ blok, ...rest }) => {

	if  (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (
			<SbEditable content={blok}>
				<Component blok={blok} {...rest}/>
			</SbEditable>
		);
	}

	return <></>;
};

export default DynamicComponent;
