import { useEffect, useState } from 'react';
import { useWindowSize } from './use-window-size';


export const useView = () => {
	const { width } = useWindowSize();
	const [ desktopView, setDesktopView ] = useState(null);
	const [ tabletView, setTabletView ] = useState(null);

	useEffect(() => {
		if(width) {
			setDesktopView(width >= 1024);
			setTabletView(width >= 768);
		}
	}, [width]);

	return { isDesktop: desktopView, isTablet: tabletView };
};