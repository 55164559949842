import React from 'react';
import Header from './header';
import { gsap } from 'gsap';
import ScrollTrigger from '../lib/ScrollTrigger';
import Footer from './footer';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, header, footer }) => {

	return (
		<div className='root-wrapper'>
			<Header headerLinks={header} />
			<main className='main'>
				{children}
			</main>
			<Footer {...footer} />
		</div>
	);
};

export default Layout;