import React, { useEffect, useRef } from 'react';
import CSSRulePlugin from '../lib/CSSRulePlugin';
import ScrollTrigger from '../lib/ScrollTrigger';
import gsap from 'gsap';
import { useWindowSize } from '../hooks/use-window-size';

gsap.registerPlugin(CSSRulePlugin);
const DEFAULT_WIDTH = 554;

const RisingSun = ({ className = '' }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { width } = useWindowSize();

	useEffect(() => {
		ScrollTrigger.matchMedia({
			'(min-width: 1024px)': () => {
				const afterElOfBackground = CSSRulePlugin.getRule('.rising-sun__background:after');
				gsap.fromTo(afterElOfBackground, { cssRule:{ y: 90 } }, {
					cssRule:{ y: -90 },
					scrollTrigger: {
						trigger: containerRef.current,
						start: 'top 70%',
						end: 'top 20%',
						scrub: 1,
						invalidateOnRefresh: true,
					},
				});
			},
			'(max-width: 1023px)': () => {
				const afterElOfBackground = CSSRulePlugin.getRule('.rising-sun__background:after');
				gsap.fromTo(afterElOfBackground, { cssRule:{ y: 90 } }, {
					cssRule:{ y: -90 },
					scrollTrigger: {
						trigger: containerRef.current,
						start: 'top 80%',
						end: 'top 30%',
						scrub: 1,
						invalidateOnRefresh: true,
					},
				});
			},
		});
	}, []);

	useEffect(() => {
		const maxWidth = containerRef.current.offsetWidth;
		const scale = maxWidth < DEFAULT_WIDTH ? (maxWidth / DEFAULT_WIDTH)  : 1;
		gsap.set('#risingSunClip', { scale: scale });
	}, [width]);

	return (
		<div ref={containerRef} className={`rising-sun ${className}`}>
			<svg xmlns='http://www.w3.org/2000/svg' className={`${className} rising-sun__svg`}>
				<clipPath id='risingSunClip'>
					<circle cx='43.51' cy='342.71' r='32' fill='none'/>
					<circle cx='43.51' cy='266.71' r='32' fill='none'/>
					<circle cx='43.51' cy='190.71' r='32' fill='none'/>
					<circle cx='43.51' cy='114.71' r='32' fill='none'/>
					<circle cx='43.51' cy='38.71' r='32' fill='none'/>
					<circle cx='356.51' cy='342.71' r='32' fill='none'/>
					<circle cx='356.51' cy='266.71' r='32' fill='none'/>
					<circle cx='356.51' cy='190.71' r='32' fill='none'/>
					<circle cx='356.51' cy='114.71' r='32' fill='none'/>
					<circle cx='356.51' cy='38.71' r='32' fill='none'/>
					<circle cx='197.51' cy='342.71' r='32' fill='none'/>
					<circle cx='197.51' cy='266.71' r='32' fill='none'/>
					<circle cx='197.51' cy='190.71' r='32' fill='none'/>
					<circle cx='197.51' cy='114.71' r='32' fill='none'/>
					<circle cx='197.51' cy='38.71' r='32' fill='none'/>
					<circle cx='510.51' cy='342.71' r='32' fill='none'/>
					<circle cx='510.51' cy='266.71' r='32' fill='none'/>
					<circle cx='510.51' cy='190.71' r='32' fill='none'/>
					<circle cx='510.51' cy='114.71' r='32' fill='none'/>
					<circle cx='510.51' cy='38.71' r='32' fill='none'/>
					<circle cx='120.51' cy='342.71' r='32' fill='none'/>
					<circle cx='120.51' cy='266.71' r='32' fill='none'/>
					<circle cx='120.51' cy='190.71' r='32' fill='none'/>
					<circle cx='120.51' cy='114.71' r='32' fill='none'/>
					<circle cx='120.51' cy='38.71' r='32' fill='none'/>
					<circle cx='433.51' cy='342.71' r='32' fill='none'/>
					<circle cx='433.51' cy='266.71' r='32' fill='none'/>
					<circle cx='433.51' cy='190.71' r='32' fill='none'/>
					<circle cx='433.51' cy='114.71' r='32' fill='none'/>
					<circle cx='433.51' cy='38.71' r='32' fill='none'/>
					<circle cx='274.51' cy='342.71' r='32' fill='none'/>
					<circle cx='274.51' cy='266.71' r='32' fill='none'/>
					<circle cx='274.51' cy='190.71' r='32' fill='none'/>
					<circle cx='274.51' cy='114.71' r='32' fill='none'/>
					<circle cx='274.51' cy='38.71' r='32' fill='none'/>
				</clipPath>
			</svg>
			<div className='rising-sun__background'></div>
		</div>
	);
};

export default RisingSun;