import React, { useMemo } from 'react';
import { IHero } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw } from '../utils/utils';
import Picture from './picture';

const Hero = ({ text, video, image }: IHero) => {
	const html = useMemo(() => richTextResolver(text), []);
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 554, width: 480, media: '(max-width: 480px)' },
	];

	return (
		<section className='hero'>
			{video 
				?
				<video className='hero__video' autoPlay playsInline loop muted poster={image?.filename}>
					<source src={video.filename} type='video/mp4' />
				</video>
				:
				<Picture image={image} queries={queries} />
			}
			<h1 className='hero__content box-wide'>
				<div className='hero__content__text' dangerouslySetInnerHTML={htmlRaw(html)}></div>
			</h1>
		</section>
	);
};

export default Hero;
