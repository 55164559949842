import { Link, navigate } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useView } from '../hooks/use-view';
import Logo from '../svg/logo';
import { ILink } from '../types/global.types';
import { timeout } from '../utils/utils';
import CustomLink from './custom-link';

const Header = ({ headerLinks }: {headerLinks: ILink[]}) => {
	const { isTablet } = useView();
	const [ menuIsVisible, setMenuIsVisible ] = useState(false);
	const [ menuIsActive, setMenuIsActive ] = useState(false);
	const [ documentScrollOffset, setDocumentScrollOffset ] = useState(0);

	const menuHandler = () => {
		if(menuIsActive) {
			closeMenu();
		} else {
			setMenuIsVisible(true);
		}
	};

	const closeMenu = () => {
		document.body.classList.remove('body--fixed');
		document.documentElement.scrollTo(0, documentScrollOffset);

		setMenuIsActive(false);
	};

	const mobileNavigationHandler = (link) => {
		closeMenu();
		navigate(link);
	};

	useEffect(() => {

		const setCloseMenu = async() => {
			if (!menuIsActive && menuIsVisible) {
				await timeout(300);
				setMenuIsVisible(false);
			} else if (menuIsActive && menuIsVisible) {
				await timeout(300);
				setDocumentScrollOffset(document.documentElement.scrollTop);
				document.body.classList.add('body--fixed');
			}
		};

		setCloseMenu();
	}, [menuIsActive]);

	useEffect(() => {
		if (menuIsVisible && !menuIsActive) {

			const setOpenMenu = async() => {
				await timeout(100);
				setMenuIsActive(true);
			};

			setOpenMenu();
		}

	}, [menuIsVisible]);

	useEffect(() => {
		if(menuIsActive) {
			closeMenu();
		}
	}, [isTablet]);

	return (
		<header className={`header ${menuIsVisible ? 'header--menu-visible' : '' } ${menuIsActive ? 'header--menu-active' : '' }`}>
			<div className='box-wide header__inner-wrapper'>
				<Link to='/'><Logo className='header__logo' /></Link>
				{!isTablet && <button className='header__mobile-button' onClick={menuHandler}>{menuIsActive ? 'Close' : 'Menu'}</button>}
				{isTablet ? 
					<nav className='header__navigation'>
						{headerLinks?.map((link, index) => 
							<CustomLink className='header__link' link={link} key={index} />,
						)}
					</nav>
					:
					<nav className='header__mobile-navigation'>
						<div className='header__mobile-navigation__inner-wrapper box-wide'>
							{headerLinks?.map((link, index) => (
								link.component === 'link' ?
									link?.link?.anchor ? <button className='header__link' key={index} onClick={() => mobileNavigationHandler(`#${link?.link?.anchor}`)}>{link.caption}</button>
										: <a className='header__link' key={index} href={link?.link?.url} onClick={closeMenu} target='_blank' rel='noreferrer'>{link.caption}</a>
									: <button key={index} id={`${link.id}`} type='button' className={`header__link ${link.classes}`} >{link.caption}</button>
							))}
						</div>
					</nav>
				}
			</div>
		</header>
	);
};

export default Header;
