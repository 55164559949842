import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { IComponent, IIconsCarousel } from '../types/global.types';
import ComponentWrapper from './component-wrapper';

gsap.registerPlugin(ScrollTrigger);

const IconsCarousel = ({ blok: { anchor, headline, description, iconsBlocks, backgroundColor }}: IComponent<IIconsCarousel>) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const innerContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		gsap.fromTo(containerRef.current, {
			x: 0,
		}, {
			x: () => -(containerRef.current.scrollWidth - window.innerWidth + innerContainerRef.current.offsetLeft),
			ease: 'none',
			scrollTrigger: {
				trigger: '.icons-carousel',
				start: 'top top',
				end: () => `+=${containerRef.current.scrollWidth - window.innerWidth + innerContainerRef.current.offsetLeft}`,
				scrub: true,
				pin: true,
				invalidateOnRefresh: true,
				anticipatePin: 1,
			},
		});

		return () => {
			gsap.killTweensOf(containerRef.current);
			gsap.killTweensOf(innerContainerRef.current);
		};
	}, []);

	return (
		<ComponentWrapper anchor={anchor} className='icons-carousel' backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}>
			<div ref={containerRef} className='icons-carousel__scroll-container'>
				<div ref={innerContainerRef} className='box-wide icons-carousel__inner-wrapper'>
					<div className='icons-carousel__item icons-carousel__item--intro'>
						<h2 className='icons-carousel__item__headline'>{headline}</h2>
						<p className='icons-carousel__item__description'>{description}</p>
					</div>
					{iconsBlocks.map((block, index) =>
						<div key={index} className='icons-carousel__item'>
							<img className='icons-carousel__item__icon' src={block.icon.filename} />
							<p className='icons-carousel__item__text'>{block.text}</p>
						</div>,
					)}
				</div>
			</div>
		</ComponentWrapper>
	);
};

export default IconsCarousel;
