import React from 'react';
import { IComponent, ITwoMediaWithText } from '../types/global.types';
import ComponentWrapper from './component-wrapper';

const TwoMediaWithText = ({ blok:
{
	anchor,
	title,
	firstMediaDescription,
	firstMediaSubtitle,
	secondMediaDescription,
	secondMediaSubtitle,
	background_color,
	text_color,
	video_1,
	video_2,
	download_label,
	downloadable_file,
}}: IComponent<ITwoMediaWithText>) => {

	return (
		<ComponentWrapper anchor={anchor} backgroundColor={background_color.value === '#FFFFFF' ? 'white-background' : 'unset'}>
			<div className='box-wide' style={{ color: text_color.value }}>
				{title && <div className='two-media-with-text__title' >{title}</div>}
				<div className='two-media-with-text'>
					<div className='two-media-with-text__container'>
						{firstMediaSubtitle && <div className='two-media-with-text__subtitle'>{firstMediaSubtitle}</div>}
						<div className='two-media-with-text__video-section'>
							<div className='two-media-with-text__video-container'>
								<video className='two-media-with-text__video' playsInline autoPlay muted loop>
									<source src={video_1} type='video/mp4'/>
								</video>
							</div>
						</div>
						<div className='two-media-with-text__text-container'>
							{firstMediaDescription && <div className='two-media-with-text__description'>{firstMediaDescription}</div>}
						</div>
					</div>

					<div className='two-media-with-text__container'>
						<div className='two-media-with-text__video-section'>
							{secondMediaSubtitle && <div className='two-media-with-text__subtitle'>{secondMediaSubtitle}</div>}
							<div className='two-media-with-text__video-container'>
								<video className='two-media-with-text__video' playsInline autoPlay muted loop>
									<source src={video_2} type='video/mp4'/>
								</video>
							</div>
						</div>
						<div className='two-media-with-text__text-container'>
							{secondMediaDescription && <div className='two-media-with-text__description'>{secondMediaDescription}</div>}
						</div>
					</div>
					{downloadable_file && downloadable_file.length > 0 ? <a
						href={downloadable_file}
						className='two-media-with-text__link'
						target='_blank'
						rel='noreferrer'
					>
						{download_label}
					</a>: null}
				</div> 
			</div>
		</ComponentWrapper>
	);
};

export default TwoMediaWithText;
