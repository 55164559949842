
import { useEffect, useState } from 'react';
import { isBrowser } from '../utils/utils';

export const useStoryblok = (originalStory, location) => {
	const [story, setStory] = useState(originalStory);

	if(typeof story.content === 'string'){
		story.content = JSON.parse(story.content);
	}

	const initEventListener = () => {
		const { StoryblokBridge } = window;

		if(typeof StoryblokBridge !== 'undefined' && isBrowser) {
			const storyblokInstance = new StoryblokBridge();
			storyblokInstance.on(['published', 'change'], () => {
				window.location.reload(true);
			});

			storyblokInstance.on('input', (event) => {
				setStory(event.story);
			});
		}
	};

	const addBridge = (callback) => {
		const existingScript = document.getElementById('storyblokBridge');
		if (!existingScript && isBrowser) {
			const script = document.createElement('script');
			script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
			script.id = 'storyblokBridge';
			document.body.appendChild(script);
			script.onload = () => {
				callback();
			};
		} else {
			callback();
		}
	};
  
	useEffect(() => {
		if(location.search.includes('_storyblok')) {
			addBridge(initEventListener);
		}
	});
	return story;
};
