import React from 'react';
import { ReactNode } from 'react';

type ComponentWrapperProps = {
	children: ReactNode | ReactNode[],
	anchor: string;
	backgroundColor?: string;
	className?: string,
}

const ComponentWrapper = ({ children, anchor, className, backgroundColor }: ComponentWrapperProps) => {

	return (
		<section id={anchor} className={`${className} ${backgroundColor ?? 'white-background'}`}>
			{children}
		</section>
	);
};

export default ComponentWrapper;
