import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ILink } from '../types/global.types';

type CustomLinkProps = {
	link: ILink;
	children?: ReactNode | ReactNode[];
	className?: string;
}

const CustomLink = (props: CustomLinkProps) => {
	const { link, className='', children=null } = props;
	const classes = `link ${className}`;

	if(link.component === 'link_cta') {
		return (
			<button id={link.id} type='button' className={`${classes} ${link.classes} header__link--cta`}>{link.caption}</button>
		);
	}

	if(!link.link) { return <></>; }

	if(link.link.anchor) {
		return (
			<AnchorLink to={`/#${link.link.anchor}`} className={classes}>{children || link.caption}</AnchorLink>
		);
	}

	if(link.link.url) {
		return (
			<a href={link.link.url} target='_blank' rel='noreferrer' className={classes}>{children || link.caption}</a>
		);
	}

	return(
		<Link to={`/${link.link.cached_url}`} className={classes}>{children || link.caption}</Link>
	);
};

export default CustomLink;
