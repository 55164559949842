import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useMemo } from 'react';
import { IComponent, IFlippingTextComponent } from '../types/global.types';
import { richTextResolver } from '../utils/rich-text-resolver';
import { htmlRaw, timeout } from '../utils/utils';
import AnimatedWheel from './animated-wheel';
import ComponentWrapper from './component-wrapper';

const FlippingTextComponent = ({ blok: { bodyText, flippingTextList, anchor, backgroundColor }}: IComponent<IFlippingTextComponent>) => {
	const html = useMemo(() => richTextResolver(bodyText), []);
	const animatedWheelRef = useRef(null);
	const [ currentIndex, setCurrentIndex ] = useState(0);

	const flip = () => {
		setInterval(async () => {
			animatedWheelRef.current.play();
			await timeout(270);

			setCurrentIndex((prev) => {
				if(prev === flippingTextList.length -1 ) {
					return 0;
				}
				return ++prev;
			});
		}, 2700);
	};

	useEffect(() => {
		flip();
	}, []);
	
	return (
		<ComponentWrapper anchor={anchor} className='card-wrapper flipping-text-component' backgroundColor={backgroundColor ? backgroundColor[0]?.color : 'unset'}>
			<div className='box-wide flipping-text-component__inner'>
				<div className='flipping-text-component__body-text content-editor' dangerouslySetInnerHTML={htmlRaw(html)}></div>
				<AnimatedWheel ref={animatedWheelRef} />
				<div className='flipping-text-component__flipping-section'>
					{flippingTextList.map((item, index) => (
						<div className={`flipping-text-component__flipping-item__wrapper flipping-text-component__flipping-item--${index === currentIndex ? 'active' : 'hidden'}`} key={item.headline}>
							<h3 className='flipping-text-component__flipping-item flipping-text-component__flipping-item--headline'>
								<span className='flipping-text-component__flipping-item__animating-caption'>{item.headline}</span>
								<span className='flipping-text-component__flipping-item__shadow'>{item.headline}</span>
							</h3>
							<p className='flipping-text-component__flipping-item flipping-text-component__flipping-item--body'>
								<span className='flipping-text-component__flipping-item__animating-caption'>{item.bodyText}</span>
								<span className='flipping-text-component__flipping-item__shadow'>{item.bodyText}</span>
							</p>
						</div>
					))}
				</div>
			</div>
		</ComponentWrapper>
	);
};

export default FlippingTextComponent;
